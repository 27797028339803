import React from 'react'

const ContentLayout = ({ title, image, color = "md:text-yellow-300", children }) => {
  return (
    <div>
      <div
        className="min-h-screen pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 mb-20 bg-contain bg-right-top bg-no-repeat hidden md:flex md:flex-col items-center"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="container mt-10 md:mt-20 font-title self-start">
          <h1 className={`text-4xl md:text-5xl text-red-700 px-2 font-bold ` + color} >
            {title}
          </h1>
        </div>
        {children}
      </div>

      <div className="w-full mt-5 font-title self-center block md:hidden">
        <h1 className="text-3xl text-red-700 leading-tight uppercase">
          {title}
          </h1>
        {children}
      </div>
    </div>
  );
}

export default ContentLayout;
