import React, { Component } from "react";
import PropTypes from "prop-types";
import Link from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import contact from "../images/austin-distel-uLnmmE8Y0E4-unsplash.jpg";
import ContentLayout from "../components/content-layout";
import axios from "axios";

class ContactForm extends Component {
  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
    submitted: false,
    status: null
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleOnSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    this.setState({
      submitted: true
    });
    axios({
      method: "post",
      url: "https://getform.io/f/d2afc502-8661-4462-a616-cb5d91e3e884",
      data: new FormData(form)
    })
      .then(r => {
        console.log(r);
        this.handleServerResponse(true, "Thanks", form)
      })
      .catch(r => {
        this.handleServerResponse(false, r.response.data.error, form)
      })
  }

  handleServerResponse = (ok, msg, form) => {
    this.setState({
      submitted: false,
      status: { ok, msg }
    });
    console.log('handling response')
    if (ok) {
      form.reset();
      this.setState({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
      console.log(this.state)
    }
  }

  render() {
    return (
      <div className="w-full max-w-lg mx-auto mt-4">
        <form
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-t border-gray-200 bg-gray-100"
          onSubmit={this.handleOnSubmit}
        >
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Your name
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="name"
                id="name"
                placeholder="e.g. Carl or Karen"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </label>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Your email address
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="email"
                name="email"
                id="email"
                placeholder="e.g. someone@somewhere.com"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </label>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              I would to talk to someone about
            </label>
            <div className="relative">
              <select
                className="shadow appearance-none w-full border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline"
                name="subject"
                id="subject"
                value={this.state.subject}
                onChange={this.handleChange}
              >
                <option value="">Please select</option>
                <option value="Bespoke Web Development">
                  Bespoke Web Development
                </option>
                <option value="Site Design and Build">
                  Site Design and Build
                </option>
                <option value="Mobile Web or App">Mobile Web or App</option>
                <option value="Cloud-based Web Application">
                  Cloud-based Web Application
                </option>
                <option value="Training">Training</option>
                <option value="Bespoke Services">Bespoke Services</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 top-2 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Tell us more about what you're looking for
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="message"
                id="message"
                rows="5"
                placeholder="I'm looking for ..."
                value={this.state.message}
                onChange={this.handleChange}
              />
            </label>
          </div>
          <div className="md:flex md:items-center">
            <div className="md:w-1/3"></div>
            <div className="md:w-2/3">
              <button
                className="shadow bg-red-700 disabled:bg-gray-300 disabled:cursor-not-allowed hover:bg-red-600 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="submit"
                disabled={this.state.name === "" || this.state.email === "" || this.state.subject === "" || this.state.message === ""}
              >
                Submit
              </button>
            </div>
          </div>
          {!this.state.submitted && this.state.status && (
            <div className="md:flex md:items-center">
              {this.state.status.ok && (
                <p className="font-bold mt-4">Thank you for your message. A member of our team will contact you shortly to discuss your requirements.</p>
              )}
              {!this.state.status.ok && (
                <p className="font-bold mt-4">We're sorry, but there was an issue with submitting this information, please try again.</p>
              )}
            </div>
          )}
        </form>
      </div>
    );
  }
}

const Contact = () => {
  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Contact" />
      <ContentLayout title={`Contact Us`} image={contact} color={`md:text-black`}>
        <div className="container w-full sm:w-7/12 lg:w-5/12 xl:1/4 mt-6 md:mt-16 sm:ml-10 self-start">
          <div
            className="bg-white rounded flex flex-col sm:flex-row items-start sm:items-center text-sm p-4"
            style={{
              boxShadow:
                "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)",
            }}
          >
            <div className="flex-1">
              <p>
                <strong>Phone:</strong> +44 (0)7815 166434
              </p>
              <p className="break-normal">
                <strong>Email: </strong>clive@distortedthinking.agency
              </p>
            </div>
          </div>
        </div>
        <div className="w-full">
          <p className="mt-10 text-2xl font-title font-bold text-center">
            Talk to us and make your next project a reality
          </p>
          <p className="text-xl text-gray-600 font-title text-center">
            Give us a ring, send an email or fill in the form below and we'll
            get back to you as soon as possible
          </p>
        </div>
        <ContactForm />
        <div className="w-full text-center">
          <h3 className="mt-10 text-2xl">Office Hours</h3>
          <table className="w-full md:w-3/4 mx-auto text-left border-collapse">
            <tbody>
              <tr className="border-t h-10">
                <td className="font-semibold">Monday</td>
                <td>7:30am</td>
                <td>-</td>
                <td>6:00pm</td>
              </tr>
              <tr className="border-t h-10">
                <td className="font-semibold">Tuesday</td>
                <td>7:30am</td>
                <td>-</td>
                <td>6:00pm</td>
              </tr>
              <tr className="border-t h-10">
                <td className="font-semibold">Wednesday</td>
                <td>7:30am</td>
                <td>-</td>
                <td>6:00pm</td>
              </tr>
              <tr className="border-t h-10">
                <td className="font-semibold">Thursday</td>
                <td>7:30am</td>
                <td>-</td>
                <td>6:00pm</td>
              </tr>
              <tr className="border-t h-10">
                <td className="font-semibold">Friday</td>
                <td>7:30am</td>
                <td>-</td>
                <td>6:00pm</td>
              </tr>
              <tr className="border-t h-10">
                <td className="font-semibold">Saturday</td>
                <td>7:30am</td>
                <td>-</td>
                <td>6:00pm</td>
              </tr>
              <tr className="border-t h-10">
                <td className="font-semibold">Sunday</td>
                <td>7:30am</td>
                <td>-</td>
                <td>6:00pm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ContentLayout>
    </Layout>
  );
};

export default Contact;
